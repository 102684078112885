import logo from './logo.svg';
import './App.css';
import { useState, useEffect } from 'react';
import Home from './Home';

function App() {

  const [view, setView] = useState("main"); // home, about, contact
  const [count, setCount] = useState(0);
  useEffect(() => {})
  
  function myFunction(e) {
    e.preventDefault();
    alert("Suuuuusss");
   setView("home")
  }
  return (
    <>
    <div className="App">
      <h1>Page still in progress</h1>
      <button onClick={myFunction}>Try it</button>
    </div>
    {view === "home" && <Home />}
    </>
      
    
 
  );
}

export default App;
